<template>
  <div class="failure">
    <img src="@/assets/img/pay/failure.png" alt="" />
    <br />
    <div>支付失败</div>
  </div>
</template>
<script>
export default {
  name: "PayFailure",
};
</script>
<style scoped lang="scss">
.failure {
  margin-top: 106px;
  text-align: center;
  div {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    line-height: 14px;
    margin-top: 20px;
  }
}
</style>